<template>
    <Datepicker
        ref="datepicker"
        :inputClassName="inputName"
        :modelValue="modelValue"
        autocomplete="off"
        calendarCellClassName="dp-custom-cell"
        :autoApply="true"
        :closeOnAutoApply="true"
        :enableTimePicker="false"
        :menuClassName="`dp-custom-menu dp-custom-menu-${position}`"
        :monthYearComponent="monthYear"
        :monthChangeOnScroll="false"
        :noSwipe="true"
        :locale="$i18n.locale"
        v-bind="{
            name,
            clearable,
            disabled,
            disabledDates,
            format,
            minDate,
            maxDate,
            multiCalendars,
            placeholder,
            position,
            range,
            startDate,
            allowedDates,
            monthNameFormat,
            hideOffsetDates,
        }"
        @update:modelValue="updateValue"
        @cleared="onCleared"
        @closed="onClose"
        @open="onOpen"
        @updateMonthYear="val => $emit('updateMonthYear', val)"
    >
        <template v-slot:action-preview="props">
            <!-- @slot This slot replaces the date preview section in the action row -->
            <slot
                name="action-preview"
                v-bind="props"
            ></slot>
        </template>
        <template v-slot:input-icon>
            <Icon
                v-if="!clearable || !modelValue"
                name="calendar"
                class="w-6 h-6"
            />
        </template>
        <template v-slot:clear-icon="{ clear }">
            <Icon
                name="close"
                class="w-6 h-6"
                @click.stop="clear"
            />
        </template>
    </Datepicker>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import moment from 'moment';
import Icon from '@/components/ui/Icon';

import '@vuepic/vue-datepicker/dist/main.css';

export default {
    components: { Icon, Datepicker },

    props: {
        /**
         * Sets the input name attribute
         */
        name: {
            type: String,
            default: null,
        },

        /**
         * Sets value. For range: [startDate, endDate]
         */
        modelValue: {
            type: String,
            default: null,
        },

        /**
         * Add a clear icon to the input field
         */
        clearable: {
            type: Boolean,
            default: true,
        },

        /**
         * Disables the input
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Disable specific dates
         */
        disabledDates: {
            type: [Function, Array], //String[] | (date: Date) => boolean, //return true for a disabled date
        },

        /**
         * Format the value of the date(s) in the input field
         */
        format: {
            type: String,
            default: 'MM/dd/yyyy',
        },

        /**
         * Add a custom class to the input field
         */
        inputClassName: {
            type: String,
            default: null,
        },

        /**
         * All dates before the given date will be disabled
         */
        minDate: {
            type: String,
            default: null,
        },

        /**
         * All dates after the given date will be disabled
         */
        maxDate: {
            type: String,
            default: null,
        },

        /**
         * Show multiple calendars side by side
         */
        multiCalendars: {
            type: Boolean,
            default: false,
        },

        /**
         * Input placeholder
         */
        placeholder: {
            type: String,
            default: '',
        },

        /**
         * Input placeholder
         */
        position: {
            type: String,
            default: 'center',
            validator: function (value) {
                return ['left', 'center', 'right'].indexOf(value) !== -1;
            },
        },

        /**
         * Range picker mode
         */
        range: {
            type: Boolean,
            default: false,
        },

        /**
         * Open the datepicker to some preselected month and year
         */
        startDate: {
            type: String,
            default: null,
        },

        /**
         * Allow only specific dates
         */
        allowedDates: {
            type: Array,
            default: () => [],
        },

        /**
         * Month/Year custom component
         */
        monthYear: {
            type: Object,
        },

        /**
         * Set the month name format
         */
        monthNameFormat: {
            type: String,
            default: 'short',
        },

        /**
         * Hide dates from the previous/next month in the calendar
         */
        hideOffsetDates: {
            type: Boolean,
            default: true,
        },
    },

    emits: [
        /**
         * Emitted when the value is changed
         */
        'change',
        /**
         * Emitted when the value is selected
         */
        'update:modelValue',
        /**
         * Emitted when the value is cleared on clear button
         */
        'clear',
        /**
         * Emitted when the datepicker menu is closed
         */
        'close',
        /**
         * Emitted when the month or year is changed
         */
        'updateMonthYear',
        /**
         * Emitted when the datepicker menu is open
         */
        'open',
    ],

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        datePreview() {
            if (!this.modelValue) {
                return null;
            }

            if (this.range) {
                const [startDate, endDate] = this.modelValue;

                if (!startDate) {
                    return null;
                }

                return `${this.getFormattedDate(startDate)} - ${endDate ? this.getFormattedDate(endDate) : ''}`;
            }

            return this.getFormattedDate(this.modelValue);
        },

        inputName() {
            return this.inputClassName ? [this.inputClassName, 'input'].join(' ') : 'input';
        },
    },

    methods: {
        getFormattedDate(date) {
            return moment(date).format(this.format);
        },

        cancel() {
            this.$refs.datepicker.closeMenu();
        },

        clearValue() {
            this.$refs.datepicker.clearValue();
        },

        onCleared() {
            this.$emit('clear');
        },

        onOpen() {
            this.isOpen = true;
            this.$emit('open');
        },

        onClose() {
            this.isOpen = false;
            this.$emit('close');
        },

        updateValue(value) {
            this.$emit('update:modelValue', value);
        },
    },
};
</script>

<style>
.dp__arrow_top {
    @apply hidden;
}

.dp__menu {
    width: calc(100% - 3rem);
    @apply rounded-lg md:w-auto;
    transition: none;
}

.dp__main,
.dp__input,
.dp__menu,
.dp__calendar_wrap {
    @apply font-ffdin;
}

.dp__input::placeholder {
    @apply opacity-100;
}

.dp__input {
    @apply block w-full border border-gray-250 outline-none p-3 rounded-lg font-ffdin font-medium text-xl text-gray-700;
}

.dp__input_icon_pad {
    @apply pl-3 pr-10;
}

.dp__input_icon {
    @apply left-auto right-3 text-gray-450;
}

.dp__clear_icon {
    @apply right-3;
}

.dp__calendar {
    @apply w-full;
}

.dp__calendar_header {
    @apply w-full;
}

.dp__calendar_header_separator {
    @apply hidden;
}

.dp__calendar_header_item {
    @apply w-auto;
}

.dp__cell_inner {
    @apply w-auto;
}

.dp__calendar_item {
    width: calc(100% / 7);
}

.dp__active_date {
    @apply text-white bg-purple-600;
}

.dp__today {
    @apply border-purple-600;
}
</style>
