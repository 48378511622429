<template>
    <FinalField
        :name="name"
        :validate="validate"
    >
        <template v-slot="props">
            <div class="form-field">
                <label
                    class="label"
                    :class="{ 'hidden md:block': $attrs.multiple && props.value.length }"
                >
                    {{ label }}
                </label>

                <div v-if="!editMode">
                    <slot
                        name="option"
                        v-bind="getSelectedOption(props.value)"
                    >
                        {{ getSelectedValue(props.value) }}
                    </slot>
                </div>

                <SelectInput
                    v-if="editMode"
                    :title="label"
                    :modelValue="props.value"
                    :buttonLabel="buttonLabel"
                    :options="options"
                    :disabled="disabled"
                    :trackBy="trackBy"
                    :labelKey="labelKey"
                    :objectMode="objectMode"
                    v-bind="$attrs"
                    @adaptiveStateUpdated="$emit('adaptive-state-updated', $event)"
                    @update:modelValue="props.change($event)"
                >
                    <template #slide-panel-header="slotProps">
                        <slot
                            name="slide-panel-header"
                            v-bind="slotProps"
                        />
                    </template>

                    <template #adaptive-header>
                        <slot name="adaptive-header" />
                    </template>

                    <template #selected-label="slotProps">
                        <slot
                            name="selected-label"
                            v-bind="slotProps"
                        />
                    </template>
                </SelectInput>

                <div
                    v-if="helperText"
                    class="field-hint"
                >
                    {{ helperText }}
                </div>

                <FieldError
                    v-if="!suppressError"
                    :name="name"
                />
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import SelectInput from '@/components/ui/SelectInput';
import FieldError from '@/components/form/FieldError';

export default {
    name: 'SelectField',

    components: {
        FinalField,
        SelectInput,
        FieldError,
    },

    inheritAttrs: false,

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        helperText: {
            type: String,
        },

        options: {
            type: Array,
            required: false,
        },

        trackBy: {
            type: String,
            default: 'id',
        },

        labelKey: {
            type: String,
            default: 'value',
        },

        validate: {
            type: [Function, Array],
            required: false,
        },

        disabled: {
            type: Boolean,
            required: false,
        },

        editMode: {
            type: Boolean,
            default: true,
        },

        objectMode: {
            type: Boolean,
            default: false,
        },

        buttonLabel: {
            type: String,
        },

        suppressError: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    emits: ['adaptive-state-updated'],

    methods: {
        getSelectedValue(value) {
            return this.getSelectedOption(value)?.[this.labelKey] ?? '-';
        },

        getSelectedOption(value) {
            if (!value) {
                return {};
            } else if (typeof value === 'object') {
                return value;
            } else {
                return this.options.find(option => option[this.trackBy] === value);
            }
        },
    },
};
</script>
